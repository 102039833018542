import { config } from './app.config.mjs'
import * as Sentry from '@sentry/browser'

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: import.meta.env.PUBLIC_ASTRO_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [/\//, config.app.hostname],
      }),
    ],
    tracesSampler: (samplingContext) => {
      if (samplingContext.transactionContext.name === '/') {
        return 0.5
      }

      return 0.1
    },
  })
}
