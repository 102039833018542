const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const postLead = (body: { email: string }) =>
  fetch('/api/lead', {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  }).then((e) => e.json())

const postStripeCheckout = (body: { productId: string; mode: string }) =>
  fetch('/api/stripe/checkout', {
    method: 'POST',
    headers,
    body: JSON.stringify({
      successUrl: new URL('/app/onboarding', window.location.origin),
      cancelUrl: window.location.href,
      ...body,
    }),
  }).then((e) => e.json())

const postStripePortal = ({ returnUrl = window.location.href }: { returnUrl?: string }) =>
  fetch('/api/stripe/portal', {
    method: 'POST',
    headers,
    body: JSON.stringify({ returnUrl }),
  }).then((e) => e.json())

/**
 * A simple HTTP client.
 * Astro has introduced experimental support for actions in 4.8
 * @todo https://github.com/withastro/roadmap/blob/actions/proposals/0046-actions.md
 *
 * @todo ~~Generate client from pages/api/*, OpenAPI spec or use isomorphic tRPC functions.~~
 * @todo Add Sentry error handling
 * @todo Instrument
 * @todo Add caching for GET requests
 */
export const client: {
  get: typeof fetch
  post: typeof fetch
  postLead: typeof postLead
  postStripeCheckout: typeof postStripeCheckout
  postStripePortal: typeof postStripePortal
} = {
  get: fetch,
  post: (input: string | URL | globalThis.Request, init?: RequestInit) =>
    fetch(input, { ...init, method: 'POST' }),
  postLead,
  postStripeCheckout,
  postStripePortal,
}
